<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="body">
      <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form" v-if="!this.$route.params.patrolrecordid">
        <a-form-model-item><a-button @click="handleAdd">新增</a-button></a-form-model-item>
        <a-form-model-item><a-button type="danger" @click="handleDelete">删除</a-button></a-form-model-item>
        <a-form-model-item><a-button type="primary" @click="confirm">保存</a-button></a-form-model-item>
      </a-form-model>
      <a-table :columns="columns" :data-source="tableData" :scroll="{ y: 750 }" :rowKey="(record,index)=> index" :pagination="false" bordered>
        <template
            v-for="col in ['linenumber','zero','one','two','three','four','five','six','seven','eight', 'nine', 'ten','eleven','twelve','thirteen', 'fourteen','fifteen','sixteen','seventeen', 'eighteen','nineteen','twenty','twentyone','twentytwo','twentythree']"
            :slot="col"
            slot-scope="text, record, index"
        >
          <div :key="col">
            <a v-if="col == 'linenumber'" style="width: 65px" @click="showSchemeModal(record)">{{record.linenumber}}</a>
            <a-select
                placeholder=""
                :disabled="true"
                v-if="col == 'zero'"
                v-model="record.zero"
                @change="statusChange(record,record.zero)"
                :style="{width:65,color:(record.zero==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
<!--              <a-select-option :value="2"> 报警 </a-select-option>-->
            </a-select>
            <a-select
                placeholder=""
                :disabled="true"
                v-if="col == 'one'"
                v-model="record.one"
                @change="statusChange(record,record.one)"
                :style="{width:65,color:(record.one==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
<!--              <a-select-option :value="2"> 报警 </a-select-option>-->
            </a-select>
            <a-select
                placeholder=""
                :disabled="true"
                v-if="col == 'two'"
                v-model="record.two"
                @change="statusChange(record,record.two)"
                :style="{width:65,color:(record.two==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
<!--              <a-select-option :value="2"> 报警 </a-select-option>-->
            </a-select>
            <a-select
                placeholder=""
                :disabled="true"
                v-if="col == 'three'"
                v-model="record.three"
                @change="statusChange(record,record.three)"
                :style="{width:65,color:(record.three==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
<!--              <a-select-option :value="2"> 报警 </a-select-option>-->
            </a-select>
            <a-select
                placeholder=""
                :disabled="true"
                v-if="col == 'four'"
                v-model="record.four"
                @change="statusChange(record,record.four)"
                :style="{width:65,color:(record.four==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
<!--              <a-select-option :value="2"> 报警 </a-select-option>-->
            </a-select>
            <a-select
                placeholder=""
                :disabled="true"
                v-if="col == 'five'"
                v-model="record.five"
                @change="statusChange(record,record.five)"
                :style="{width:65,color:(record.five==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
<!--              <a-select-option :value="2"> 报警 </a-select-option>-->
            </a-select>
            <a-select
                placeholder=""
                :disabled="true"
                v-if="col == 'six'"
                v-model="record.six"
                @change="statusChange(record,record.six)"
                :style="{width:65,color:(record.six==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
<!--              <a-select-option :value="2"> 报警 </a-select-option>-->
            </a-select>
            <a-select
                placeholder=""
                :disabled="true"
                v-if="col == 'seven'"
                v-model="record.seven"
                @change="statusChange(record,record.seven)"
                :style="{width:65,color:(record.seven==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
<!--              <a-select-option :value="2"> 报警 </a-select-option>-->
            </a-select>
            <a-select
                placeholder=""
                :disabled="true"
                v-if="col == 'eight'"
                v-model="record.eight"
                @change="statusChange(record,record.eight)"
                :style="{width:65,color:(record.eight==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
<!--              <a-select-option :value="2"> 报警 </a-select-option>-->
            </a-select>
            <a-select
                placeholder=""
                :disabled="true"
                v-if="col == 'nine'"
                v-model="record.nine"
                @change="statusChange(record,record.nine)"
                :style="{width:65,color:(record.nine==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
<!--              <a-select-option :value="2"> 报警 </a-select-option>-->
            </a-select>
            <a-select
                placeholder=""
                :disabled="true"
                v-if="col == 'ten'"
                v-model="record.ten"
                @change="statusChange(record,record.ten)"
                :style="{width:65,color:(record.ten==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
<!--              <a-select-option :value="2"> 报警 </a-select-option>-->
            </a-select>
            <a-select
                placeholder=""
                :disabled="true"
                v-if="col == 'eleven'"
                v-model="record.eleven"
                @change="statusChange(record,record.eleven)"
                :style="{width:65,color:(record.eleven==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
<!--              <a-select-option :value="2"> 报警 </a-select-option>-->
            </a-select>
            <a-select
                placeholder=""
                :disabled="true"
                v-if="col == 'twelve'"
                v-model="record.twelve"
                @change="statusChange(record,record.twelve)"
                :style="{width:65,color:(record.twelve==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
<!--              <a-select-option :value="2"> 报警 </a-select-option>-->
            </a-select>
            <a-select
                placeholder=""
                v-if="col == 'thirteen'"
                :disabled="true"
                v-model="record.thirteen"
                @change="statusChange(record,record.thirteen)"
                :style="{width:65,color:(record.thirteen==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
<!--              <a-select-option :value="2"> 报警 </a-select-option>-->
            </a-select>
            <a-select
                placeholder=""
                :disabled="true"
                v-if="col == 'fourteen'"
                v-model="record.fourteen"
                @change="statusChange(record,record.fourteen)"
                :style="{width:65,color:(record.fourteen==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
<!--              <a-select-option :value="2"> 报警 </a-select-option>-->
            </a-select>
            <a-select
                placeholder=""
                :disabled="true"
                v-if="col == 'fifteen'"
                v-model="record.fifteen"
                @change="statusChange(record,record.fifteen)"
                :style="{width:65,color:(record.fifteen==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
<!--              <a-select-option :value="2"> 报警 </a-select-option>-->
            </a-select>
            <a-select
                placeholder=""
                :disabled="true"
                v-if="col == 'sixteen'"
                v-model="record.sixteen"
                @change="statusChange(record,record.sixteen)"
                :style="{width:65,color:(record.sixteen==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
<!--              <a-select-option :value="2"> 报警 </a-select-option>-->
            </a-select>
            <a-select
                placeholder=""
                :disabled="true"
                v-if="col == 'seventeen'"
                v-model="record.seventeen"
                @change="statusChange(record,record.seventeen)"
                :style="{width:65,color:(record.seventeen==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
<!--              <a-select-option :value="2"> 报警 </a-select-option>-->
            </a-select>
            <a-select
                placeholder=""
                :disabled="true"
                v-if="col == 'eighteen'"
                v-model="record.eighteen"
                @change="statusChange(record,record.eighteen)"
                :style="{width:65,color:(record.eighteen==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
<!--              <a-select-option :value="2"> 报警 </a-select-option>-->
            </a-select>
            <a-select
                placeholder=""
                :disabled="true"
                v-if="col == 'nineteen'"
                v-model="record.nineteen"
                @change="statusChange(record,record.nineteen)"
                :style="{width:65,color:(record.nineteen==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
<!--              <a-select-option :value="2"> 报警 </a-select-option>-->
            </a-select>
            <a-select
                placeholder=""
                :disabled="true"
                v-if="col == 'twenty'"
                v-model="record.twenty"
                @change="statusChange(record,record.twenty)"
                :style="{width:65,color:(record.twenty==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
<!--              <a-select-option :value="2"> 报警 </a-select-option>-->
            </a-select>
            <a-select
                placeholder=""
                :disabled="true"
                v-if="col == 'twentyone'"
                v-model="record.twentyone"
                @change="statusChange(record,record.twentyone)"
                :style="{width:65,color:(record.twentyone==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
<!--              <a-select-option :value="2"> 报警 </a-select-option>-->
            </a-select>
            <a-select
                placeholder=""
                :disabled="true"
                v-if="col == 'twentytwo'"
                v-model="record.twentytwo"
                @change="statusChange(record,record.twentytwo)"
                :style="{width:65,color:(record.twentytwo==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
<!--              <a-select-option :value="2"> 报警 </a-select-option>-->
            </a-select>
            <a-select
                placeholder=""
                :disabled="true"
                v-if="col == 'twentythree'"
                v-model="record.twentythree"
                @change="statusChange(record,record.twentythree)"
                :style="{width:65,color:(record.twentythree==2?'red':'')}"
            >
              <a-select-option :value="0"> {{''}} </a-select-option>
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="2"> 异常 </a-select-option>
<!--              <a-select-option :value="2"> 报警 </a-select-option>-->
            </a-select>
          </div>
        </template>
      </a-table>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData"></add-or-edit-modal>
    <add-or-edit-alarm-modal :visible.sync="alarmModalVisible" :show-type="alarmModalShowType" :detailData="alarmModalDetailData"></add-or-edit-alarm-modal>
    <patrol-scheme-modal :visible.sync="schemeModalVisible" :show-type="schemeModalShowType" :detailData="schemeModalDetailData"></patrol-scheme-modal>
  </page-container>
</template>
<script>
import {mapState} from "vuex";
import {getMonitorPointNameList} from "A/monitoring";
import {addPatrolRecord, addPatrolRecordData, getPatrolSchemeTemplate} from "A/patrol";
import addOrEditModal from "V/patrols/patrolsRecord/abnormalRecord/addOrEditModal";
import addOrEditAlarmModal from "V/patrols/patrolsRecord/alarmRecord/addOrEditModal";
import moment from "moment";
import patrolSchemeModal from "V/material/patrolScheme/patrolSchemeModal";
export default {
  name: "addOrEditPatrolsRecord",
  components:{
    addOrEditModal,
    addOrEditAlarmModal,
    patrolSchemeModal
  },
  data() {
    return {
      moment,
      time:['zero','one','two','three','four','five','six','seven','eight', 'nine', 'ten','eleven','twelve','thirteen', 'fourteen','fifteen','sixteen','seventeen', 'eighteen','nineteen','twenty','twentyone','twentytwo','twentythree'],
      styleLists:["width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;",
        "width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;","width: 65px;",],
      columns:[
        {
          title: '序号',
          dataIndex: 'keynum',
          // width: 40,
          align: 'center',
        },
        {
          title: '管理类型',
          colSpan: 1,
          dataIndex: 'managetype',
          align: 'center',
          // width: 160
        },
        {
          title: '巡查线路编号',
          dataIndex: 'linenumber',
          align: 'center',
          // width: 120
        },
      ],
      tableData:[],
      option:['正常','异常'],
      queryParams:{
        monitorpointnum:'',
      },
      monitorpointNameList:[],
      count:20,
      modalVisible: false,
      modalShowType: 'edit',
      modalDetailData: null,
      alarmModalVisible: false,
      alarmModalShowType: 'edit',
      alarmModalDetailData: null,
      schemeModalVisible: false,
      schemeModalShowType: 'edit',
      schemeModalDetailData: null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "巡查记录"
    },
    breadcrumb() {
      const pages = [{name:this.$route.params.modalTitle?this.$route.params.modalTitle:"项目巡查与报警",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  created() {
    if(this.$route.params.patrolrecordid){
      this.getMonitorpointName(this.$route.params.patrolrecordid)
    }else{
      this.getMonitorpointName()
    }
  },
  methods: {
    showSchemeModal(record){
      this.schemeModalVisible=true
      this.schemeModalDetailData=record.patrolschemeid
    },
    statusChange(record,time,col){
      if(time==1){
        this.modalDetailData={
          monitorpointnum:this.queryParams.monitorpointnum,
          eventrecordid:'',
          eventnum:'',
          managetype:record.managetype,
          eventcode:'',
          eventstarttime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          starttime:'',
          eventtype:902,
          location:'',
          description:'',
          measure:'',
          reports:"无",
          repairorder:'无',
          followreturn:'',
          finishsign:'',
          confirmman:'',
          eventstatus: null,
        }
        this.modalShowType='patrol'
        this.modalVisible=true
      }else if(time==2){
        this.alarmModalDetailData={
          eventrecordid:'',
          eventnum:'',
          eventname:'',
          managetype:record.managetype,
          systemnameid: '',
          eventstarttime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          eventtype:901,
          feedback:'',
          eventcode:'',
          monitorpointnum:this.queryParams.monitorpointnum,
          location:'',
          description:'',
          measure:'',
          reports:"无",
          repairorder:'无',
          timerange:'',
          followreturn:'',
          confirmman:'',
          arriveman:'',
          arrivetime:'',
          recordman:'',
          eventstatus: null,
        }
        this.alarmModalShowType='patrol'
        this.alarmModalVisible=true
      }
    },
    getMonitorpointName(patrolrecordid){
      getMonitorPointNameList({userdepid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.queryParams.monitorpointnum=this.$route.params.monitorpointnum?this.$route.params.monitorpointnum:res.item[0].monitorpointnum
          this.getPatrolSchemeTemplate(this.queryParams.monitorpointnum,patrolrecordid,this.$route.params.patrolshift)
        }
      })
    },
    getPatrolSchemeTemplate(monitorpointnum,patrolrecordid,patrolshift){
      this.columns=[
        {
          title: '序号',
          dataIndex: 'keynum',
          align: 'center',
          width:60
        },
        {
          title: '管理类型',
          colSpan: 1,
          dataIndex: 'managetype',
          align: 'center',
          width:180
        },
        {
          title: '巡查线路编号',
          dataIndex: 'linenumber',
          align: 'center',
          scopedSlots: { customRender: 'linenumber' },
          width:120
        },
      ]
      getPatrolSchemeTemplate({monitorpointnum:monitorpointnum,patrolrecordid: patrolrecordid,patrolshift:patrolshift}).then(res=>{
        if(res&&res.returncode=='0') {
          let suffix = res.start.substring(3,5)
          let start = parseInt(res.start.substring(0,2))
          let end = suffix=="00"?parseInt(res.end.substring(0,2))+1:parseInt(res.end.substring(0,2))
          let intervals = res.intervals==0?1:res.intervals==1?2:4
          let lastHour=0
          if (start > end) {
            for (let i = start; i < 24; i+=intervals) {
              this.columns.push({
                title: i + ':'+suffix,
                dataIndex: this.time[i],
                align: 'center',
                scopedSlots: {customRender: this.time[i]},
              })
              if(i<24){lastHour=i}
            }
            for (let i = 0-(24-lastHour); i < end; i+=intervals) {
              if(i<0){continue}
              this.columns.push({
                title: i + ':'+suffix,
                dataIndex: this.time[i],
                align: 'center',
                scopedSlots: {customRender: this.time[i]},
              })
            }
          } else {
            for (let i = start; i < end; i+=intervals) {
              this.columns.push({
                title: i + ':'+suffix,
                dataIndex: this.time[i],
                align: 'center',
                scopedSlots: {customRender: this.time[i]},
              })
            }
          }
          this.tableData = res.item
        }
      })
    },
    handleAdd() {
      const { tableData } = this;
      const newData = {
        keynum: parseInt(this.tableData[this.tableData.length-1].keynum)+1,
        managetype: '随机抽查',
        linenumber: parseInt(this.tableData[this.tableData.length-1].linenumber)+1,
        zero:0,
        one:0,
        twp:0,
        three:0,
        four:0,
        five:0,
        six:0,
        seven:0,
        eight: 0,
        nine: 0,
        ten: 0,
        eleven: 0,
        twelve: 0,
        thirteen: 0,
        fourteen: 0,
        fifteen: 0,
        sixteen: 0,
        seventeen: 0,
        eighteen: 0,
        nineteen: 0,
        twenty: 0,
        twentyone: 0,
        twentytwo: 0,
        twentythree: 0,
      };
      this.tableData = [...tableData, newData];
    },
    handleDelete(){
      this.tableData.splice(this.tableData.length-1,1)
    },
    confirm(){
      let params={
        monitorpointnum:this.queryParams.monitorpointnum,
        patroldate:moment(new Date()).format("YYYYMMDDHHmmss")
      }
      addPatrolRecord(params).then(res=>{
        if(res&&res.returncode=='0'){
          let params={
            monitorpointnum:this.queryParams.monitorpointnum,
            patrolschemeid:'',
            patrolrecordid:res.id,
            addoredit:'add',
            data:this.tableData,
          }
          addPatrolRecordData(params).then(res=>{
            if(res&&res.returncode=='0'){
              this.$message.success("操作成功")
              this.$router.go(-1)
            }
          })
        }
      })
    },
  },
}
</script>
<style scoped>
.body{
  background-color: white;
  margin: 10px;
  width: 100%
}
</style>