<template>
  <a-modal v-model="modalVisible" title="巡查方案" :width="1200" :centered="true">
    <template slot="footer">
      <a-button @click="modalVisible=false">关闭</a-button>
    </template>
    <div>
      <div class="body">
        <h3 style="font-weight: bold;font-size: 30px;text-align: center">巡查方案</h3>
      </div>
      <div class="body">
        <a-table :columns="columns" :rowKey="(record,index)=> index" :data-source="tableData" :pagination="false" bordered>
          <template
              v-for="col in ['patroltype', 'managetype', 'linenumber', 'inspectionarea','frequency','post','remark']"
              :slot="col"
              slot-scope="text, record, index"
          >
            <div :key="col">
              <span
                  v-if="col == 'patroltype'"
                  style="margin: -5px 0"
                  :value="text">{{patrolTypes[record.patroltype]?patrolTypes[record.patroltype].value:''}}</span>
              <span
                  v-if="col == 'managetype'"
                  style="margin: -5px 0"
                  :value="text">{{record.managetype}}</span>
              <span
                  v-if="col == 'linenumber'"
                  style="margin: -5px 0"
                  :value="text">{{record.linenumber}}</span>
              <span
                  v-if="col == 'inspectionarea'"
                  style="margin: -5px 0"
                  :value="text">{{record.inspectionarea}}</span>
              <span
                  placeholder="请输入频次/时段"
                  v-if="col == 'frequency'"
                  style="margin: -5px 0"
                  :value="text">{{record.frequency}}</span>
              <span
                  v-if="col == 'post'"
                  style="margin: -5px 0"
                  :value="text">{{record.post}}</span>
              <span
                  v-if="col == 'remark'"
                  style="margin: -5px 0"
                  :value="text">{{record.remark}}</span>
            </div>
          </template>
        </a-table>
      </div>
    </div>
  </a-modal>
</template>

<script>
import {mapState} from "vuex";
import {getItemFromArrayByKey} from "U/index";
import {getMonitorPointNameList} from "A/monitoring";
import {addPatrolScheme, addPatrolSchemeData, getPatrolSchemeDataListByCondition} from "A/patrol";
import {patrolShifts, patrolTypes} from "@/json/wlwhistory";
export default {
  name: "patrolSchemeModal",
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
  },
  data(){
    return {
      modalVisible: false,
      patrolShifts,
      patrolTypes,
      columns:[
        {
          title: '序号',
          dataIndex: 'keynum',
          width: 40,
        },
        {
          title: '巡查方式',
          colSpan: 1,
          dataIndex: 'patroltype',
          width: 80,
          scopedSlots: { customRender: 'patroltype' },
        },
        {
          title: '管理类型',
          colSpan: 1,
          dataIndex: 'managetype',
          width: 80,
          scopedSlots: { customRender: 'managetype' },
        },
        {
          title: '巡查线路编号',
          dataIndex: 'linenumber',
          align: 'center',
          width: 100,
          scopedSlots: { customRender: 'linenumber' },
        },
        {
          title: '巡查区域/位置（摄像机编号）',
          dataIndex: 'inspectionarea',
          scopedSlots: { customRender: 'inspectionarea' },
        },
        {
          title: '频次/时段',
          dataIndex: 'frequency',
          width: 80,
          scopedSlots: { customRender: 'frequency' },
        },
        {
          title: '呼叫/巡查岗位',
          dataIndex: 'post',
          width: 110,
          scopedSlots: { customRender: 'post' },
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 120,
          scopedSlots: { customRender: 'remark' },
        },
      ],
      tableData:[
        {
          patrolschemedataid:null,
          keynum: 1,
          patroltype: '系统巡查',
          managetype: '巡查管理-公区巡查',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 2,
          patroltype: '系统巡查',
          managetype: '巡查管理-公区巡查',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 3,
          patroltype: '系统巡查',
          managetype: '巡查管理-公区巡查',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 4,
          patroltype: '系统巡查',
          managetype: '巡查管理-电梯巡查',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 5,
          patroltype: '系统巡查',
          managetype: '巡查管理-电梯巡查',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 6,
          patroltype: '系统巡查',
          managetype: '巡查管理-电梯巡查',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 7,
          patroltype: '系统巡查',
          managetype: '巡查管理-车场巡查',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 8,
          patroltype: '系统巡查',
          managetype: '巡查管理-车场巡查',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 9,
          patroltype: '系统巡查',
          managetype: '巡查管理-车场巡查',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 10,
          patroltype: '系统巡查',
          managetype: '出入管理-行人出入口',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 11,
          patroltype: '系统巡查',
          managetype: '出入管理-大堂',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 12,
          patroltype: '系统巡查',
          managetype: '出入管理-车辆出入口',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 13,
          patroltype: '系统巡查',
          managetype: '设施设备-设备房巡查',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 14,
          patroltype: '系统巡查',
          managetype: '设施设备-重点设施设备',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 15,
          patroltype: '系统巡查',
          managetype: '设施设备-抄表',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 16,
          patroltype: '系统巡查',
          managetype: '恶劣天气-危险位置',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 17,
          patroltype: '系统巡查',
          managetype: '恶劣天气-首层最低位置',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 18,
          patroltype: '系统巡查',
          managetype: '恶劣天气-地下室最低位',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
        {
          patrolschemedataid:null,
          keynum: 19,
          patroltype: '系统巡查',
          managetype: '随机抽查',
          linenumber: '',
          inspectionarea: '',
          frequency:'',
          post:'',
          remark:'',
        },
      ],
      queryParams:{
        monitorpointid:'',
        monitorpointnum:'',
        patrolname:'',
        patrolshift:'',
        requirements:'',
        areadivision:'',
        focuspoint:'',
        principle:'',
        regulation:'',
        timerange:'',
      },
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "巡查方案"
    },
    breadcrumb() {
      const pages = [{name:"工作查阅",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.getMonitorpointName()
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    }
  },
  created() {

  },
  methods: {
    getMonitorpointName(){
      getMonitorPointNameList({userdepid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.queryParams.monitorpointnum=res.item.length>0?res.item[0].monitorpointnum:""
          getPatrolSchemeDataListByCondition({patrolschemeid:this.detailData}).then(res=>{
            if(res&&res.returncode=='0'){
              this.tableData=res.item
            }
          })
        }
      })
    },
    operationClick({key}){
      let arr = key.split('-');
      let keyid = arr[1];
      let type = arr[0];
      let record = getItemFromArrayByKey(this.tableData, 'keynum', keyid);
      if(type=='add'){
        this.tableData.splice(record.keynum,0, {
          keynum: record.keynum+1,
          patroltype: '',
          managetype: '',
          lineNumber: '',
          inspectionArea: '',
          frequency:'',
          post:'',
          remark:'',})
        for(let i=record.keynum+1;i<this.tableData.length;i++){
          this.tableData[i].keynum++
        }
      }else if(type=='delete'){
        this.tableData.splice(record.keynum-1,1)
        for(let i=record.keynum-1;i<this.tableData.length;i++){
          this.tableData[i].keynum--
        }
      }
    },
    confirm() {
      this.$refs.queryForm.validate(valid => {
        if (valid) {
          let params = {
            ...this.queryParams
          }
          addPatrolScheme(params).then(res => {
            if (res && res.returncode == '0') {
              let params = {
                patrolschemeid: res.id,
                addoredit: this.tableData[0].patrolschemedataid ? 'edit' : 'add',
                data: this.tableData
              }
              addPatrolSchemeData(params).then(res => {
                if (res && res.returncode == '0') {
                  this.$message.success("操作成功")
                  this.$router.go(-1)
                }
              })
            }
          })
        }
      })
    }
  },
}
</script>

<style scoped>
.body{
  background-color: white;
  margin: 10px;
}
</style>